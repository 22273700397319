import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import SettingsNav from "./settingsNav";
import Account from "./account";
import MyResturants from "./myRestaurants";
import MyProviders from "./myProviders";
import MyIngredients from "./myIngredients";
import MyOrders from "./myOrders";
import GroupRestaurants from "./competitiveSet";
import MyMeals from "./myMeals";
import Events from "./events";
import UserManagement from "./userManagement";
// import Subscription from "./subscription";

import "./index.scss";
import { useUserData } from "contexts/AuthContextManagement";
import storage from "services/storage";

const ALL_TABS = [
  "Account",
  // "UserManagement",
  "MyRestaurants",
  "MyProviders",
  "Ingredients",
  "MyOrders",
  "MyMeals",
  // "CompetitiveSet",
  "GroupRestaurants",
  "Events",
  // "Subscription"
];

function Settings() {
  const location = useLocation();
  const { id: userId } = useUserData();
  const user = JSON.parse(storage.getItem("user"));

  const [filteredTabs, setFilteredTabs] = useState([]);

  useEffect(() => {
    // Filter the tabs based on the user condition
    const tabs = ALL_TABS.filter(tab => {
      if (tab === "UserManagement" && user.is_plan_required) {
        return false;
      }
      // if (tab === "Subscription" && !user.is_plan_required) {
      //   return false;
      // }
      return true;
    });
    setFilteredTabs(tabs);
  }, [userId]);

  const [selectedNav, setSelectedNav] = useState(() =>
    location?.state?.tab === "events" ? ALL_TABS[8] : ALL_TABS[0]
  );

  useEffect(() => {
    // Reset selectedNav if it's filtered out
    if (!filteredTabs.includes(selectedNav)) {
      setSelectedNav(filteredTabs[0]);
    }
  }, [filteredTabs, selectedNav]);

  const selectedNavView = () => {
    switch (selectedNav) {
      case "Account":
        return <Account />;
      // case "UserManagement":
      //   return <UserManagement />;
      case "MyRestaurants":
        return <MyResturants />;
      case "MyProviders":
        return <MyProviders />;
      case "Ingredients":
        return <MyIngredients />;
      case "MyOrders":
        return <MyOrders />;
      case "MyMeals":
        return <MyMeals />;
      case "GroupRestaurants":
        return <GroupRestaurants />;
      case "Events":
        return <Events />;
      // case "Subscription":
      //   return <Subscription />;
      default:
        return <></>;
    }
  };

  return (
    <div className="settings">
      <SettingsNav
        selectedNav={selectedNav}
        setSelectedNav={setSelectedNav}
        TABS={filteredTabs}
      />
      {selectedNavView()}
    </div>
  );
}

export default Settings;
