import { useEffect } from "react";

import { NavLink, useLocation, useHistory } from "react-router-dom";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { useTranslation } from "react-i18next";

import Routes from "routes/router";
import useWindowSize from "customHooks/useWindowResize";
import storage from "services/storage";
import { MAX_COLLAPISBLE_SIDEBAR } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { useFilterData } from "contexts/FilterContextManagment";

import Logo from "assets/images/cible_l.png";
import FullSoonLogo from "assets/images/Fullsoon.png";
import CloseSidebarIcon from "assets/images/icon/CLOSED_SIDEBAR.svg";
import { ReactComponent as LogoutSvg } from "assets/images/icon/LOGOUT.svg";

import "./sidebar.scss";

const START_DATE = new Date(new Date().setDate(new Date().getDate() - 1));
const END_DATE = new Date(new Date().setDate(new Date().getDate() - 1));

export const routes_mapping = {
  "customers_predictions": "occupancy",
  "ingredients_predictions": "stock",
  "meals_predictions": "meals",
  "stock_management": "stock",
  "automatic_orders": "stock",
  "financial_analysis": "finance",
  "market_view": "market view",
  "meals_predictions": "forecast",
}

function Sidebar({ isCollapsed, setIsCollapsed }) {
  const location = useLocation();
  const history = useHistory();
  const [width] = useWindowSize();
  const { t } = useTranslation();
  const { id: userId, 
    setRestaurants,
    setSelectedRestaurantId,
    hasRetaurants
   } = useUserData();
  const {
    setSelectedFilterMeals,
    setSelectedFinanceFilterMeals,
    setSelectedFilterMyRestaurants,
    setSelectedFilterProducts,
    setFilterStartEndDate,
    setFilterFormData,
    setSelectedCompetitorList,
    setEventTypeData
  } = useFilterData();

  const user = JSON.parse(storage.getItem("user"));

  /* hide routes manually/temporarily for clients of some restaurants 
    as currently we don't have roles/permissions implemented */
    let routes = Routes;
    if(user?.subscription?.plan === 'standard' && user?.subscription?.features) {
        const basic = user.subscription.features.map(f => routes_mapping[f])
        routes = Routes.filter(route => {
        if (basic.includes(route.name.toLowerCase()) || route.name === 'Settings') {
          return true
        } else {
          return false
        }
      })
    }
  
  if (userId === '45eedd99-ef55-4086-92b4-885fe101aaa2') { // koox soho
    routes = routes.filter(r => (
        r.name !== 'Forecast' && r.name !== 'Occupancy' && 
        r.name !== 'Service' && r.name !== 'eReputation' && 
        r.name !== 'Market view' && r.name !== 'Donation'
      )
    );
  } else if (userId === '362d2961-f07b-47cf-b5d9-50ad3bcbd9e0') { // dumbo petites-ecuries
    routes = routes.filter(r => (
        r.name !== 'Service' && r.name !== 'eReputation' && 
        r.name !== 'Market view' && r.name !== 'Donation'
      )
    );
  } else if (userId === 'c1c08029-2d95-43a1-a2cb-0515ad8c8e8b') { // crêpe touch
    routes = routes.filter(r => (
        r.name !== 'Service' && r.name !== 'Stock' && 
        r.name !== 'eReputation' && r.name !== 'Market view' && 
        r.name !== 'Donation'
      )
    );
  } else if (user.is_plan_required) { // admin tab removed for other users
    routes = routes.filter(r => (
        r.name !== 'Admin'
      )
    );
  }
  // Filter out the labo route if there are no Restaurants
  if (hasRetaurants) {
    routes = routes.filter(route => route.path !== "/labo");
  }  

  useEffect(() => {
    setTimeout(() => {
      const resizeEvent = window.document.createEvent("UIEvents");
      resizeEvent.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(resizeEvent);
    }, 50);
  }, [isCollapsed]);

  const activeRoute = (routeName) =>
    location.pathname?.indexOf(routeName) > -1 ? "selected" : "";

  const onCollapsedSidebar = () => {
    setIsCollapsed(() => !isCollapsed);
  };

  const collapsedSidebar =
    width < MAX_COLLAPISBLE_SIDEBAR || isCollapsed || false;

  const onLogoClick = () => {
    history.push("/");
  };

  const onLogout = (e) => {
    e.preventDefault();
    storage.clear();
    setRestaurants([], false);
    setSelectedRestaurantId("");
    setSelectedFilterMeals([]);
    setSelectedFinanceFilterMeals([]);
    setSelectedFilterMyRestaurants([]);
    setSelectedFilterProducts([]);
    setFilterStartEndDate({
      start_date: "",
      end_date: "",
    });
    setFilterFormData({ total: true });
    setSelectedCompetitorList([]);
    setEventTypeData([]);
    history.push("/signin");
  };

  return (
    <>
      {width >= MAX_COLLAPISBLE_SIDEBAR && (
        <img
          src={CloseSidebarIcon}
          alt=""
          className={`toggleIcon ${collapsedSidebar ? "collapsed" : ""}`}
          onClick={onCollapsedSidebar}
        />
      )}
      <ProSidebar collapsed={collapsedSidebar}>
        <Menu>
          <aside className="leftmenu ">
            <div className="logomain cursor-pointer" onClick={onLogoClick}>
              <img src={Logo} className="img-fluid mx-3 " alt="" />
              {!collapsedSidebar && (
                <img src={FullSoonLogo} className="img-fluid hidesm" alt="" />
              )}
            </div>
            <ul
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {routes.map(({ path, name, icon: Icon, keyName }, key) => (
                <MenuItem key={key}>
                  <NavLink
                    to={path}
                    className={`sidebar-link ${activeRoute(
                      path
                    )} mb-0`}
                    activeClassName="active"
                  >
                    <span className="menu-icon">{Icon}</span>
                    <span className="menu-txt">{t(name)}</span>
                  </NavLink>
                </MenuItem>
              ))}

              <MenuItem>
                <NavLink to="/logout" className="logoutbtn" onClick={onLogout}>
                  <span className="menu-icon">
                    <LogoutSvg />
                  </span>
                  <span className="menu-txt">{t("LogOut")} </span>
                </NavLink>
              </MenuItem>
            </ul>
          </aside>
        </Menu>
      </ProSidebar>
    </>
  );
}

export default Sidebar;
